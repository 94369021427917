<template>
  <main @click="clearChat">
    <i class="ri ri-close-line" aria-hidden="true"></i>
    {{ title }}
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'
import * as messangerGetters from '@/store/modules/messanger/types/getters'

export default {
  name: 'ClearChat',
  computed: {
    ...mapGetters('messanger', {
      chat: messangerGetters.GET_ACTIVE_CHAT
    }),
    title () {
      return this.chat.isGroup ? 'Clear chat' : 'Clear all chats history'
    }
  },
  methods: {
    ...mapActions('messanger', {
      clearChatById: messangerActions.CLEAR_CHAT_BY_ID
    }),
    async clearChat () {
      // Using HTML string
      const h = this.$createElement
      const titleVNode = h('div', { domProps: { innerHTML: `<p>Are you sure you want to delete all message history with ${this.chat.title}?</p> <p>This action cannot be undone.</p> <p><input id="all" type="checkbox" name="all" /><label class="ml-2">Also delete for ${this.chat.title}.</label></p>` } })
      const response = await this.$bvModal.msgBoxConfirm(
        [titleVNode],
        {
          title: '',
          okTitle: 'Delete',
          okVariant: 'uppercase-warning',
          cancelTitle: 'Cancel',
          cancelVariant: 'uppercase'
        }
      )
      if (response) {
        this.clearChatById(this.chat.id)
      }
    }
  }
}
</script>
