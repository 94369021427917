<template>
  <div class="d-flex align-items-center" @click="editMessage">
    <i class="ri-edit-box-line"></i>
    <div class="ml-2">Edit</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'

export default {
  name: 'EditMessage',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('messanger', {
      setEditMessageById: messangerActions.SET_EDIT_MESSAGE_BY_ID,
      updateDraftMessage: messangerActions.UPDATE_DRAFT_MESSAGE
    }),
    async editMessage () {
      await this.setEditMessageById(this.id)
    }
  }
}
</script>
