<template>
  <b-modal
    id="chat-forward-message"
    size="md"
    @close="discardForwardMessage"
    no-close-on-backdrop
    no-close-on-esc
    content-class="rounded"
    title="Forward to..."
    no-fade
    scrollable
  >
    <template #modal-title>
      <div class="d-flex align-items-center justify-content-start">
        Forward to...
      </div>
      <div class="header-caption">You can forward each message to 20 recipients at a time. Just click on the person name.</div>
    </template>
    <div class="d-flex justify-content-between mb-4">
      <form action="#" class="searchbox search-box-user d-flex position-relative align-items-center w-100">
        <input
          v-model="search"
          type="text"
          class="form-control router-link-active search-input"
          placeholder="Search for contacts..."
        >
        <div class="comment-attagement d-flex">
          <b-link href="javascript:void(0);">
            <i class="ri-search-line mr-2"></i>
          </b-link>
        </div>
      </form>
    </div>
    <div v-if="forwardTo.length">
      <h6 class="text-uppercase">Members</h6>
      <div class="d-flex flex-wrap tagged-scrollable">
        <div class="tag-people-wrapper mr-2 mb-2" v-for="(item, index) in forwardTo" :key="index">
          <div>{{ item.title }}</div>
          <div class="ml-2 cursor-pointer" @click="removeForward(item.id)">X</div>
        </div>
      </div>
    </div>
    <div>
      <h6 class="text-uppercase mb-2">Suggestions</h6>
    </div>
    <div class="scrolable members-search-results">
      <ul id="chat-list-data" class="iq-chat-ui nav nav-pills flex-column">
        <li class="nav-item" v-for="(item) in filteredChatList" :key="item.id" @click="addForward(item)">
          <a class="nav-link" href="javascript:void(0)">
            <ChatItem :item="item" class="pl-2" />
          </a>
        </li>
      </ul>
    </div>
    <template #modal-footer="{ cancel }">
      <b-button variant="uppercase" @click="cancel()">Cancel</b-button>
      <button :disabled="!forwardTo.length" @click="forwardMessageHandler" type="button" class="btn btn-chat-send d-flex align-items-center p-2">
          <img src="@/assets/images/chat/send-message.svg" alt="Send" class="img-fluid svg">
        </button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import * as systemNotificationActions from '@/store/modules/system-notification/types/actions'

import ChatItem from '@/components/homeiz/messanger/ChatItemShort'

export default {
  name: 'ForwardMessageModal',
  components: {
    ChatItem
  },
  props: {
    chatList: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      search: '',
      forwardTo: []
    }
  },
  computed: {
    ...mapGetters('messanger', {
      selectedMessages: messangerGetters.GET_SELECTED_MESSAGES,
      forwardMessage: messangerGetters.GET_FORWARD_MESSAGE
    }),
    filteredChatList () {
      return this.chatList.filter(item => {
        return item.title.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    ...mapActions('systemNotification', {
      setDoneNotification: systemNotificationActions.SET_DONE_NOTIFICATION
    }),
    ...mapActions('messanger', {
      bulkForwardMessages: messangerActions.BULK_FORWARD_MESSAGE,
      forwardMessageAction: messangerActions.FORWARD_MESSAGE,
      discardForwardMessage: messangerActions.DISCARD_FORWARD_MESSAGE,
      clearSelectedMessages: messangerActions.CLEAR_SELECTED_MESSAGES
    }),
    addForward (chat) {
      this.forwardTo.push(chat)
    },
    removeForward (id) {
      this.forwardTo = this.forwardTo.filter(function (item) {
        return item.id !== id
      })
    },
    async forwardMessageHandler () {
      if (this.selectedMessages.length) {
        this.forwardTo.forEach(async (item) => {
          await this.bulkForwardMessages({ chatId: item.id, messages: this.selectedMessages })
        })
        await this.clearSelectedMessages()
      } else {
        this.forwardTo.forEach(async (item) => {
          await this.forwardMessageAction({ chatId: item.id, message: this.forwardMessage })
        })
      }
      this.setDoneNotification(
        {
          header: 'MeTwitt',
          text: `Your forward has been sent successfully`
        }
      )
      this.forwardTo = []
      this.$bvModal.hide(`chat-forward-message`)
    }
  }
}
</script>
