<template>
  <main>
    <div class="d-flex flex-wrap images-canvas">
      <div class="d-flex w-50" :class="canvasSize">
        <img class="post-image" :src="this.images[0].preview" @click="showImage(0)" />
      </div>
      <div class="d-block w-50" :class="imageClass">
        <div class="w-100" v-for="(item, index) in images.slice(1)" :key="index">
          <img class="post-image w-100" :src="item.preview" @click="showImage(index+1)" />
        </div>
      </div>
    </div>
    <b-modal
      :id="`media-modal-${_uid}`"
      size="lg"
      hide-header
      hide-footer
      dialog-class="bg-transparent"
      content-class="bg-transparent"
      no-fade
      v-model="showModal"
      >
        <img class="post-image" :src="images[imageIndex].preview" />
        <div class="text-white text-bold text-right cursor-pointer" @click="onCancelHandled">Close</div>
    </b-modal>
  </main>
</template>

<script>
export default {
  name: 'ChatImage',
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      imageIndex: 0,
      showModal: false
    }
  },
  computed: {
    imageClass () {
      return this.images.length > 4 ? 'images' : `images-${this.images.length}`
    },
    canvasSize () {
      return this.images.length > 1 ? 'w-50' : 'w-100'
    }
  },
  methods: {
    showImage (index) {
      this.imageIndex = index
      this.showModal = true
    },
    onCancelHandled () {
      this.showModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.img-holder {
  overflow: hidden;
  width: 100%;
  background: var(--iq-grey);
  position: relative;
  margin: 1px;
}

.post-image {
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 2px;
  border-radius: 25px !important;
}

.images-canvas {
  margin-left: 4px;
  max-width: 250px;
  display: grid;
  border-radius: 50px;
}

.post-image {
  // border-radius: 0 !important;

  &:last-child {
    margin-bottom: 0px;
  }
}
</style>
