<template>
  <main>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <ul id="chat-list-data" class="iq-chat-ui nav nav-pills flex-column">
          <li class="nav-item" v-for="index in 22" :key="index">
            <a class="nav-link" href="javascript:void(0)">
              <div class="d-flex align-items-center pl-2">
                <div class="avatar mr-3">
                  <b-skeleton type="avatar" class="avatar-50 rounded-circle"></b-skeleton>
                </div>
                <div class="chat-sidebar-name">
                  <h6 class="mb-0"><b-skeleton width="20%"></b-skeleton></h6>
                  <b-skeleton width="25%"></b-skeleton>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </template>
      <ul id="chat-list-data" class="iq-chat-ui nav nav-pills flex-column">
        <li class="nav-item" v-for="(item) in chatList" :key="item.id">
          <a class="nav-link" :class="item.id === activeChatId ? 'active' : ''" href="javascript:void(0)">
            <ChatItem :item="item" class="pl-2" />
          </a>
        </li>
      </ul>
    </b-skeleton-wrapper>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'

import ChatItem from '@/components/homeiz/messanger/ChatItem'

export default {
  name: 'ChatList',
  props: {
    chatList: {
      type: Array,
      required: true
    }
  },
  components: {
    ChatItem
  },
  data () {
    return { }
  },
  computed: {
    ...mapGetters('messanger', {
      activeChatId: messangerGetters.GET_ACTIVE_CHAT_ID,
      loading: messangerGetters.GET_CHAT_LIST_IS_LOADING
    })
  }
}
</script>
