<template>
  <main v-if="!loading">
    <b-dropdown-item href="javascript:void(0)">
      <ToggleButton :mini-toggle="true" :close-button="false" toggleShow="#chat-user-detail-popup"
        mediaClass="chat-user-profile w-100">
        <template v-slot:media>
          <i class="fa fa-info-circle mr-0" aria-hidden="true"></i>
          View group info
        </template>
        <template v-slot:body>
        </template>
      </ToggleButton>
    </b-dropdown-item>
    <b-dropdown-item href="#">
      <manage-group-chat />
    </b-dropdown-item>
    <b-dropdown-item href="#">
      <add-members-group-chat />
    </b-dropdown-item>
    <!-- <b-dropdown-item href="#">
      <i class="ri-file-chart-line"></i>
      Create poll
    </b-dropdown-item> -->
    <b-dropdown-item href="#">
      <clear-chat />
    </b-dropdown-item>
    <b-dropdown-item href="#">
      <delete-chat :chat="chat" />
    </b-dropdown-item>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import ToggleButton from '@/components/socialvue/Chat/ToggleButton'
import ManageGroupChat from '../ManageGroupChat.vue'
import DeleteChat from '../DeleteChat.vue'
import ClearChat from '../ClearChat.vue'
import AddMembersGroupChat from '../AddMembersGroupChat.vue'

export default {
  name: 'ChatGroupDropDownMenu',
  components: {
    ToggleButton,
    ManageGroupChat,
    DeleteChat,
    ClearChat,
    AddMembersGroupChat
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('messanger', {
      chat: messangerGetters.GET_ACTIVE_CHAT
    })
  }
}
</script>
