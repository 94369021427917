<template>
  <div class="emoji-window">
    <div class="d-flex justify-content-between emoji-holder w-100 flex-wrap">
      <tab-nav :pills="true"
        class="iq-chat-ui d-flex justify-content-around align-items-center w-100 pl-3 pr-3 border-bottom"
        id="chat-emoji-data">
        <template>
          <tab-nav-items :id="`v-pills-emoji-id`" href="#v-pills-emoji" :ariaControls="`v-pills-emoji`" :active="true"
            role="tab">
            <template v-slot:title>
              Emoji
            </template>
          </tab-nav-items>
          <tab-nav-items :id="`v-pills-stickers-id`" href="#v-pills-stickers" :ariaControls="`v-pills-stickers`"
            role="tab">
            <template v-slot:title>
              Stickers
            </template>
          </tab-nav-items>
          <tab-nav-items :id="`v-pills-gifs-id`" href="#v-pills-gifs" :ariaControls="`v-pills-gifs`" role="tab">
            <template v-slot:title>
              Gifs
            </template>
          </tab-nav-items>
        </template>
      </tab-nav>
      <div class="w-100">
        <tab-content id="v-pills-tabContent2" class="p-2">
          <tab-content-item :active="true" id="v-pills-emoji" aria-labelled-by="v-pills-emoji">
            <template>
              <picker
                set="apple"
                :data="emojiIndex"
                :dynamicWidth="true"
                :perLine="7"
                :showPreview="false"
                @select="addEmoji"
              />
            </template>
          </tab-content-item>
          <tab-content-item id="v-pills-stickers" aria-labelled-by="v-pills-emoji">
            <template>
              <stickers-picker />
            </template>
          </tab-content-item>
          <tab-content-item id="v-pills-gifs" aria-labelled-by="v-pills-emoji">
            <template>
              <giphy-picker />
            </template>
          </tab-content-item>
        </tab-content>
      </div>
    </div>
  </div>
</template>

<script>
import data from 'emoji-mart-vue-fast/data/all.json'
import 'emoji-mart-vue-fast/css/emoji-mart.css'

import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
import { mapGetters, mapActions } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import GiphyPicker from '../../common/GiphyPicker.vue'
import StickersPicker from '../../common/StickersPicker.vue'

let emojiIndex = new EmojiIndex(data)

const COLONS_REGEX = new RegExp(
  '([^:]+)?(:[a-zA-Z0-9-_+]+:(:skin-tone-[2-6]:)?)',
  'g'
)

export default {
  name: 'ChatWindowEmoji',
  components: { Picker, GiphyPicker, StickersPicker },
  data () {
    return {
      emojiIndex: emojiIndex
    }
  },
  computed: {
    ...mapGetters('messanger', {
      draftHtmlMessage: messangerGetters.GET_DRAFT_HTML_MESSAGE,
      draftMessage: messangerGetters.GET_DRAFT_MESSAGE,
      activeChatId: messangerGetters.GET_ACTIVE_CHAT_ID
    })
  },
  methods: {
    ...mapActions('messanger', {
      updateDraftMessage: messangerActions.UPDATE_DRAFT_MESSAGE,
      updateHtmlDraftMessage: messangerActions.UPDATE_DRAFT_HTML_MESSAGE
    }),
    addEmoji (emoji) {
      // const emojiStr = `<p class="emoji">${emoji.native}</p>`
      // const emojiNativeStr = `${emoji.native}`
      // const emojiStr = `${emoji.unified}`
      const emojiHtmlStr = this.emojiToHtml(emoji)
      let message = this.draftMessage[this.activeChatId] ? `${this.draftMessage[this.activeChatId].message}${emojiHtmlStr}` : emojiHtmlStr
      this.updateDraftMessage(message)
      // const tiny = document.getElementById('tinymce')

      // tinyMCE.getInstanceById(this.id)

      // let htmlMessage = this.draftHtmlMessage[this.activeChatId] ? `${this.draftHtmlMessage[this.activeChatId].message} ${emojiHtmlStr}` : emojiHtmlStr
      // this.updateHtmlDraftMessage(htmlMessage)
      // const textarea = this.$refs.textarea
      // const cursorPosition = textarea.selectionEnd
      // const start = this.value.substring(0, textarea.selectionStart)
      // const end = this.value.substring(textarea.selectionStart)
      // const text = start + emoji.native + end
      // this.$emit('input', text)
      // textarea.focus()
      // this.$nextTick(() => {
      //   textarea.selectionEnd = cursorPosition + emoji.native.length
      // })
    },
    emojiToHtml (emoji) {
      let style = `background-position: ${emoji.getPosition()}`
      // The src="data:image..." is needed to prevent border around img tags.
      // return `<img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/15.1.0/72x72/1f004.png" data-text="${emoji.native}" class="emoji-set-apple emoji-type-image" alt="${emoji.colons}" style='${style}'/>
      return `<img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="emoji-set-apple emoji-type-image" style='${style}'/>`
    },
    wrapEmoji (text) {
      return text.replace(COLONS_REGEX, function (match, p1, p2) {
        const before = p1 || ''
        // We add "data-text='{emoji.native}'", don't replace it
        if (text.endsWith(before, 'alt="') || text.endsWith(before, 'data-text="')) {
          return match
        }

        let emoji = emojiIndex.findEmoji(p2)
        if (!emoji) {
          return match
        }
        return before + this.emojiToHtml(emoji)
      })
    }
  }
}
</script>

<style lang="scss">
.emoji-mart {
  height: calc(100vh - 74px) !important;
  border: none !important;
}

.emoji-set-apple.emoji-type-image {
  background-image: url("https://unpkg.com/emoji-datasource-apple@14.0.0/img/apple/sheets-256/64.png")
}

</style>
