<template>
  <div>
    <div v-if="showDateTitle" class="mb-4">
      <span class="message-date">{{ $d(new Date(item.createdAt), 'short', 'en-US') }}</span>
    </div>
  </div>
</template>

<script>

export default {
  components: { },
  name: 'MessageDate',
  props: {
    item: {
      type: Object,
      required: true
    },
    previousItem: {
      type: [Object, null]
    }
  },
  data () {
    return { }
  },
  computed: {
    showDateTitle () {
      if (!this.previousItem) {
        return true
      } else {
        const itemDate = new Date(this.item.createdAt)
        itemDate.setHours(0, 0, 0, 0)
        const previousItemDate = new Date(this.previousItem.createdAt)
        previousItemDate.setHours(0, 0, 0, 0)

        return previousItemDate < itemDate
      }
    }
  }
}
</script>
