<template>
  <header class="d-flex justify-content-between align-items-center bg-white border-bottom">
    <b-skeleton-wrapper class="w-50" :loading="loading">
      <template #loading>
        <div class="pl-3">
          <h5 class="font-14 lh-13 mb-0"><b-skeleton animation="throb" variant="text-small" width="50%"></b-skeleton>
          </h5>
          <div class="font-11 lh-11"><b-skeleton animation="throb" variant="text-small" width="20%"></b-skeleton></div>
          <div class="font-11 lh-11"><b-skeleton animation="throb" variant="text-small" width="30%"></b-skeleton></div>
        </div>
      </template>
      <!-- <img :src="checkUser(5, 'image')" alt="avatar" class="avatar-50 rounded-circle cursor-pointer">
                  <span class="avatar-status"><i class="ri-checkbox-blank-circle-fill text-success" /></span> -->
      <div v-if="selectedMessages.length" class="d-flex selected-messages align-items-center justify-content-between">
        <div class="ml-2">
          <b-button variant="primary" @click="bulkForwardMessage">Forward {{ selectedMessages.length }}</b-button>
          <b-button variant="danger" class="ml-2" @click="bulkDeleteMessages">Delete {{ selectedMessages.length }}</b-button>
        </div>
        <div>
          <b-button variant="uppercase" @click="clearSelectedMessages">Cancel</b-button>
        </div>
      </div>
      <div class="pl-3">
        <h5 class="font-14 lh-13 mb-0">{{ info.title }} <span v-if="isGroup">Group</span></h5>
        <div v-if="isGroup" class="font-11">{{ membersCount }} members <span class="ml-1">2 online</span></div>
        <div v-else class="font-11">last seen {{ $d(info.lastVisit) }}</div>
        <div class="d-flex text-blue align-items-center">
          <div class="typing-dots"></div>
          <div class="user-name-typing">Michael is typing</div>
        </div>
      </div>

      <div class="chat-header-icons d-flex">
        <b-dropdown id="dropdownMenuButton2" right variant="none remove-toggle without-lines status">
          <template v-slot:button-content>
            <div class="user-status-btn">
              <div class="d-flex justify-content-center align-items-center" v-html="userStatusTitle"/>
              <i class="ml-1 ri-arrow-down-s-fill"></i>
            </div>
          </template>
          <b-dropdown-item @click="changeStatus(userStatuses.ACTIVE)">
            <i class="ri-checkbox-blank-circle-fill text-success" />
            Active
          </b-dropdown-item>
          <b-dropdown-item @click="changeStatus(userStatuses.DO_NOT_DISTURB)">
            <img src="@/assets/images/icon/do-not-disturb.svg" class="d-not-disturb"/>
            Do not disturb
          </b-dropdown-item>
          <b-dropdown-item @click="changeStatus(userStatuses.AWAY)">
            <i class="ri-checkbox-blank-circle-line text-warning" />
            Set as away
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown id="dropdownMenuButton2" right variant="none remove-toggle without-lines">
          <template v-slot:button-content>
            <i class="ri-more-2-line mr-0" />
          </template>
          <chat-drop-down-menu v-if="!isGroup"  :loading="loading"/>
          <chat-group-drop-down-menu v-else :loading="loading"/>
        </b-dropdown>
      </div>
      <chat-info :data="info" :members="members" :loading="loading" />
    </b-skeleton-wrapper>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import * as userStatuses from '@/config/messanger/userStatus'
import ChatDropDownMenu from './ChatDropDownMenu.vue'
import ChatGroupDropDownMenu from './ChatGroupDropDownMenu.vue'
import ChatInfo from './ChatInfo.vue'

export default {
  name: 'ChatWindowHeader',
  components: {
    ChatInfo,
    ChatDropDownMenu,
    ChatGroupDropDownMenu
  },
  props: {
    info: {
      type: Object,
      requred: true
    },
    isGroup: {
      type: Boolean,
      default: false
    },
    members: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    },
    chatId: {
      type: String,
      requred: true
    }
  },
  data () {
    return {
      userStatuses: userStatuses,
      userStatus: userStatuses.ACTIVE
    }
  },
  computed: {
    ...mapGetters('messanger', {
      selectedMessages: messangerGetters.GET_SELECTED_MESSAGES
    }),
    membersCount () {
      return Object.keys(this.members).length
    },
    userStatusTitle () {
      if (this.userStatus === userStatuses.DO_NOT_DISTURB) {
        return '<img src="' + require(`@/assets/images/icon/do-not-disturb.svg`) + '" class="d-not-disturb"/> <div class="text-danger">Do not disturb</div>'
      } else if (this.userStatus === userStatuses.AWAY) {
        return '<i class="ri-checkbox-blank-circle-line text-warning"></i> <div>Away</div>'
      } else {
        return '<i class="ri-checkbox-blank-circle-fill text-success"></i> <div>Active</div>'
      }
    }
  },
  methods: {
    ...mapActions('messanger', {
      clearSelectedMessages: messangerActions.CLEAR_SELECTED_MESSAGES,
      deleteMessageById: messangerActions.DELETE_MESSAGE_BY_ID
    }),
    async bulkDeleteMessages () {
      const h = this.$createElement
      const titleVNode = h('div', { domProps: { innerHTML: `<p>Do you wnat to delete these messages?</p> <p>This action cannot be undone.</p> <p><input id="all" type="checkbox" name="all" /><label class="ml-2">Delete for everyone.</label></p>` } })
      const response = await this.$bvModal.msgBoxConfirm(
        [titleVNode],
        {
          title: '',
          okTitle: 'Delete',
          okVariant: 'uppercase-warning',
          cancelTitle: 'Cancel',
          cancelVariant: 'uppercase'
        }
      )
      if (response) {
        await this.selectedMessages.forEach(async (messageId) => {
          await this.deleteMessageById(messageId)
        })

        this.clearSelectedMessages()
      }
    },
    async bulkForwardMessage () {
      this.$bvModal.show(`chat-forward-message`)
    },
    async changeStatus (status) {
      this.userStatus = status
    }
  }
}
</script>
