<template>
  <main>
    <b-dropdown id="dropdownMenuButton2" right variant="none remove-toggle erez-style-menu">
      <template v-slot:button-content>
        <img src="@/assets/images/icon/chat-more-icon.svg" alt="Actions" class="img-fluid" />
      </template>
      <b-dropdown-header>
        <div class="d-flex align-items-center text-black text-bold font-10-pt mb-2">
          <img src="@/assets/images/user/erez.jpeg" alt="avatar" class="ml-1 avatar-30 rounded-circle" />
          <div class="ml-1">Erez Johnatan</div>
        </div>
      </b-dropdown-header>
      <b-dropdown-item>
        <create-group-chat />
      </b-dropdown-item>
      <b-dropdown-item v-b-modal.theme-modal>
        <i class="ri-gallery-line mr-0" aria-hidden="true"></i>
        Change theme
      </b-dropdown-item>
    </b-dropdown>
    <b-modal id="theme-modal" size="lg" title="Select theme" hide-footer content-class="rounded" no-fade>
      <theme-selector />
    </b-modal>
  </main>
</template>

<script>

import ThemeSelector from '@/components/homeiz/messanger/ThemeSelector.vue'
import CreateGroupChat from '@/components/homeiz/messanger/CreateGroupChat.vue'
export default {
  name: 'ChatMainMenu',
  components: {
    ThemeSelector,
    CreateGroupChat
  }
}
</script>
