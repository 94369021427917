<template>
  <div class="d-flex align-items-center" @click="replyMessage">
    <i class="ri-reply-line"></i>
    <div class="ml-2">Reply</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'

export default {
  name: 'ReplyMessage',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('messanger', {
      setReplyMessageById: messangerActions.SET_REPLY_MESSAGE_BY_ID
    }),
    async replyMessage () {
      await this.setReplyMessageById(this.id)
    }
  }
}
</script>
