<template>
  <div>
    <div class="d-flex align-items-center" @click="forwardMessage">
      <i class="ri-share-forward-line"></i>
      <div class="ml-2">Forward</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'

export default {
  name: 'ForwardMessage',
  components: { },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('messanger', {
      setForwardMessage: messangerActions.SET_FORWARD_MESSAGE_BY_ID
    }),
    async forwardMessage () {
      await this.setForwardMessage(this.id)
      this.$bvModal.show(`chat-forward-message`)
    }
  }
}
</script>
