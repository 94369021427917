<template>
  <main>
        <div class="d-flex flex-wrap images-canvas">
          <div class="img-holder" :class="imageClass" v-for="(src, index) in images" :key="index">
            <!-- <ul class="actions d-flex"> -->
              <!-- <li class="cursor-pointer" @click="replacePhoto(index)"><img src="@/assets/images/icon/replace-image.svg"/></li> -->
              <!-- <li class="cursor-pointer" @click="deleteImage(index)"> -->
                <!-- <img src="@/assets/images/icon/trash-image.svg"/> -->
              <!-- </li> -->
            <!-- </ul> -->
            <img class="post-image" src="@/assets/images/file/file-uploader.png" />
            <div>{{ src.name }}</div>
            <div v-if="images.length > 5 && index === 4" @click="show">
              +{{ images.length }}
            </div>
            <!-- <div class="actions">
              <div class="absolute">
                <div>
                  <i class="ri-loop-left-line"></i>
                </div>
                <div>
                  <i class="ri-delete-bin-2-line"></i>
                </div>
              </div>
            </div> -->
          </div>
        </div>
  </main>
</template>

<script>
export default {
  name: 'FileViewer',
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    imageClass () {
      return this.images.length > 4 ? 'images' : `images-${this.images.length}`
    }
  },
  methods: {
    deleteImage (index) {
      this.$emit('delete-image', index)
    },
    replacePhoto (index) {
      this.$emit('replace-image', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.img-holder {
  overflow: hidden;
  width: 100%;
  background: var(--iq-grey);
  position: relative;

  .actions {
    position: absolute;
    top: 10px;
    right: 20px;
  }

  div {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    color: var(--iq-white);
    font-size: 32px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &:nth-child(n+6) {
    display: none;
  }

  &.images-2 {
    width: 50%;
  }

  &.images-3:nth-child(n+2) {
    width: 50%;
  }

  &.images-4:nth-child(n+2) {
    width: 33.33%;
  }

  &.images {
    width: 50%;

    &:nth-child(n+3) {
      width: 33.33%;
    }
  }
}

// .img-holder:nth-child(3):after {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   margin: auto;
//   content: 'View All';
// }

.post-image {
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
}

.images-canvas {
  border-radius: 25px;
  overflow: hidden;
}
</style>
