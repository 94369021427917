<template>
  <div class="d-flex align-items-center" @click="fetchActiveChat">
    <div class="avatar mr-3">
      <img :src="item.avatar" :alt="item.title" class="avatar-50 rounded-circle">
      <span class="avatar-status" v-if="!item.isGroup">
        <i v-if="item.onlineStatus === userStatuses.ACTIVE" class="ri-checkbox-blank-circle-fill text-success" />
        <i v-if="item.onlineStatus === userStatuses.AWAY" class="ri-checkbox-blank-circle-line text-warning" />
        <img v-if="item.onlineStatus === userStatuses.DO_NOT_DISTURB" src="@/assets/images/icon/do-not-disturb.svg" class="d-not-disturb"/>
      </span>
    </div>
    <div class="chat-sidebar-name">
      <div class="d-flex align-items-center">
        <h6 class="mb-0 d-flex flex-wrap justify-content-start align-items-center">
          <i v-if="item.isGroup" class="ri-group-fill mr-1" />
          <div class="d-flex">
            {{ item.title }}
            <div v-if="item.isGroup" class="ml-1"> Group</div>
          </div>
          <div class="public-group-label ml-2 d-flex align-items-center" v-if="item.isGroup && !item.isPrivate"> - <img src="@/assets/images/icon/public-feed.svg" alt="Public group" class="svg mr-1"/> Public group </div>
        </h6>
      </div>
      <div v-if="item.lastMessage" class="d-flex align-items-center">
        <!-- <div class="font-20 message-status text-success mr-2">
          <i v-if="item.lastMessage.isRead" class="ri-check-double-line"></i>
          <i v-else class="ri-check-line"></i>
        </div> -->
        <div class="chat-date">{{ $d(item.lastMessage.updatedAt) }}</div>
        <div
          v-if="!item.lastMessage.isRead"
          class="chat-message-counter font-size-14 text-red ml-3 d-flex align-items-center"
        >
          <img class="unread-messages-bell mr-1" src="@/assets/images/icon/bell-red-icon.svg" >
          <div>{{ item.messageCounter }}</div>
        </div>
      </div>
      <div v-if="hasDraft" class="font-10-pt message-preview"><span class="text-draft">Draft:</span> <span v-html="draftPreview"></span></div>
      <div v-if="item.lastMessage && !hasDraft" class="font-10-pt message-preview"><div v-html="messagePreview"></div></div>
    </div>
    <div class="chat-meta float-right text-center mt-2 mr-1">
      <div class="w-100" v-if="item.isBlocked">
        <unblock-chat v-model="item.isBlocked" :chat="item"/>
      </div>
      <div v-else>
        <b-dropdown id="dropdownMenuButton2" right variant="none remove-toggle without-lines">
          <template v-slot:button-content>
            <i class="ri-more-line mr-0" />
          </template>
          <b-dropdown-item href="#">
            <block-chat :chat="item"/>
          </b-dropdown-item>
          <b-dropdown-item href="#">
            <delete-chat :chat="item"/>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import * as userStatus from '@/config/messanger/userStatus'
import DeleteChat from './DeleteChat.vue'
import BlockChat from './BlockChat.vue'
import UnblockChat from './UnblockChat.vue'

export default {
  components: { DeleteChat, BlockChat, UnblockChat },
  name: 'ChatItem',
  props: {
    item: { type: Object }
  },
  data () {
    return {
      userStatuses: userStatus
    }
  },
  methods: {
    ...mapActions('messanger', {
      fetchActiveChatId: messangerActions.FETCH_ACTIVE_CHAT_BY_ID
    }),
    async fetchActiveChat () {
      await this.fetchActiveChatId(this.item.id)
    }
  },
  computed: {
    ...mapGetters('messanger', {
      draftMessage: messangerGetters.GET_DRAFT_MESSAGE
    }),
    messagePreview () {
      return this.item.lastMessage.message.substring(0, 60)
    },
    hasDraft () {
      return this.draftMessage[this.item.id] && this.draftMessage[this.item.id].message
    },
    draftPreview () {
      return this.draftMessage[this.item.id].message.substring(0, 60)
    }
  }
}
</script>
