<template>
  <main>
    <div class="d-flex flex-wrap images-canvas">
      <div class="d-flex w-100">
        <video class="post-image" :src="this.video[0].src"  />
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'ChatVideo',
  props: {
    video: {
      type: Array,
      required: true
    }
  },
  data () {
    return { }
  },
  computed: {
    path () {
      return false
    }
  },
  methods: { }
}
</script>

<style lang="scss" scoped>
.img-holder {
  overflow: hidden;
  width: 100%;
  background: var(--iq-grey);
  position: relative;
  margin: 1px;
}

.post-image {
  height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 2px;
  border-radius: 25px !important;
}

.images-canvas {
  margin-left: 4px;
  max-width: 250px;
  display: grid;
  border-radius: 50px;
}

.post-image {
  // border-radius: 0 !important;

  &:last-child {
    margin-bottom: 0px;
  }
}
</style>
