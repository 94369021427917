<template>
  <div class="chat chat-left" :key="item.id">
    <div class="chat-user">
      <a class="avatar m-0">
        <img :src="item.creator.image" alt="avatar" class="avatar-35 rounded-circle" />
      </a>
    </div>
    <div class="chat-detail" :class="isEmoji"  @click.right="messageDropDown(item.id, $event)">
      <div v-if="item.media.length" class="mb-2">
          <chat-media :files="item.media"/>
      </div>
      <div v-else :class="isSelected(item.id) ? 'selected' : ''" class="chat-message d-flex flex-wrap">
        <div class="addon-for-chat-window">
          <svg aria-hidden="true" height="12" viewBox="0 0 21 12" width="21" class="x10l6tqk xng853d xds687c"
            fill="var(--card-background)" style="transform: scale(-1, -1) translate(0px);">
            <path
              d="M20.685.12c-2.229.424-4.278 1.914-6.181 3.403L5.4 10.94c-2.026 2.291-5.434.62-5.4-2.648V.12h20.684z">
            </path>
          </svg>
        </div>
        <div>
          <div class="reply-message-hover" @click="replyMessage(item.id)">Reply</div>
          <div v-if="item.parent && !item.isForward" class="reply-message-text">
            <div class="text-bold">{{ item.parent.creator.fullName }}</div>
            <div>{{ item.parent.messagePreview }}</div>
          </div>
          <div v-if="item.parent && item.isForward">
            <div class="forward-message-text">Forwarded from {{ item.parent.creator.fullName }}</div>
            <div>{{ item.parent.messagePreview }}</div>
          </div>
          <div v-if="!item.isForward">{{ item.message }}</div>
        </div>
        <div class="message-time w-100">
          <span class="mr-1" v-if="item.isEdited">Edited</span>
          <span>{{ $d(item.createdAt, 'time', 'en-US') }}</span>
        </div>
      </div>
    </div>
    <b-dropdown size="lg" variant="link" right ref="messageDropDown" toggle-class="text-decoration-none" no-caret>
      <b-dropdown-item-button><copy-selected-text :text="textSelection"/></b-dropdown-item-button>
      <b-dropdown-item-button><reply-message :id="item.id"/></b-dropdown-item-button>
      <b-dropdown-item-button><forward-message :id="item.id"/></b-dropdown-item-button>
      <b-dropdown-item-button><delete-message :id="item.id"/></b-dropdown-item-button>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item-button><select-message :title="selectTitle" :id="item.id"/></b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import * as messangerActions from '@/store/modules/messanger/types/actions'

import DeleteMessage from './DeleteMessage.vue'
import ForwardMessage from './ForwardMessage.vue'
import ReplyMessage from './ReplyMessage.vue'
import CopySelectedText from './CopySelectedText.vue'
import SelectMessage from './SelectMessage.vue'
import ChatMedia from './ChatMedia.vue'

export default {
  components: { DeleteMessage, ReplyMessage, CopySelectedText, ForwardMessage, SelectMessage, ChatMedia },
  name: 'ChatMessage',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      textSelection: ''
    }
  },
  computed: {
    ...mapGetters('messanger', {
      selectedMessages: messangerGetters.GET_SELECTED_MESSAGES
    }),
    selectTitle () {
      return this.isSelected(this.item.id) ? 'Deselect' : 'Select'
    },
    isEmoji () {
      const str = this.item.message.replace(/<[^>]*>?/gm, '')
      return str ? '' : 'bg-transparent'
    }
  },
  methods: {
    ...mapActions('messanger', {
      setReplyMessageById: messangerActions.SET_REPLY_MESSAGE_BY_ID
    }),
    messageDropDown (id, event) {
      event.preventDefault()
      const selectedText = window.getSelection()
      this.textSelection = selectedText.toString()
      this.$refs.messageDropDown.show()
    },
    isSelected (id) {
      return this.selectedMessages.includes(id)
    },
    replyMessage (id) {
      this.setReplyMessageById(id)
    }
  }
}
</script>
