<template>
  <div>
    <div class="position-relative">
      <input placeholder="Search Stickers" class="giphy-input" name="title" v-model="search" />
      <div class="clear-button" @click="clearSearch"><i class="ri-close-circle-line"></i></div>
    </div>
    <div class="gif-container">
      <img class="cursor-pointer" v-for="gif in gifs" :src="gif" :key="gif.id" @click="submitMessage(gif)">
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
// import searchGifs from '@/services/giphy/search'
import { mapActions } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'
import ChatMessage from '@/Model/Messanger/ChatMessage'

export default {
  name: 'StickyPicker',
  data () {
    return {
      search: '',
      gifs: []
    }
  },
  watch: {
    search (val) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.performSearch()
      }, 800)
    }
  },
  methods: {
    ...mapActions('messanger', {
      sendMessage: messangerActions.SEND_MESSAGE
    }),
    async submitMessage (gif) {
      const gifHtml = `<img src=${gif}>`
      const message = new ChatMessage({ message: gifHtml })
      this.sendMessage(message)
    },
    async performSearch () {
      // const result = await searchGifs(this.search)
      let apiKey = 'OIz1TAIAlhvxQ4C3yF3cj9JffPU9Pnag'
      let searchEndPoint = 'https://api.giphy.com/v1/stickers/search?'
      let limit = 69

      let url = `${searchEndPoint}&api_key=${apiKey}&q=${this.search}&limit=${limit}`
      fetch(url)
        .then(response => {
          return response.json()
        })
        .then(json => {
          this.buildGifs(json)
        })
        .catch(err => {
          console.log(err)
        })
    },
    buildGifs (json) {
      this.gifs = json.data
        .map(gif => gif.id)
        .map(gifId => {
          return `https://media.giphy.com/media/${gifId}/giphy.gif`
        })
    },
    clearSearch () {
      this.search = ''
    }
  }
}
</script>

<style scoped lang="scss">
.gif-container {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: calc(100vh - 130px);
  overflow: scroll;

  img {
    margin-left: 10px;
    margin-bottom: 10px;
    width: 40%;
  }
}
</style>
