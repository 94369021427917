<template>
  <div class="chat-content scroller border-left theme w-100 pt-2 position-relative" :class="messangerTheme">
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <div v-for="index in 22" :key="index" class="chat chat-left me w-100">
          <div class="chat-user">
            <a class="avatar m-0">
              <b-skeleton type="avatar" class="avatar-35 rounded-circle"></b-skeleton>
            </a>
          </div>
          <div :class="`w-70`">
            <div class="chat-message d-flex w-100">
              <b-skeleton animation="throb" :width="`${200/index}%`"></b-skeleton>
            </div>
          </div>
        </div>
      </template>
      <template>
        <div>
          <div v-for="(item, index) in messages" :key="item.id">
            <message-date :item="item" :previous-item="messages[index-1]" />
            <chat-message-my :item="item" v-if="item.creator.me"  />
            <chat-message :item="item" v-else />
            <div v-if="index === messages.length - 1" id="last-message" class="last-message"></div>
          </div>
        </div>
        <div class="chat-scroll-down">
            <div class="scroll-down-inner cursor-pointer" @click="scrollDown()">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none"
                stroke="#ffffff"
                stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs"
              >
                <path d="M18 15l-6-6-6 6"/>
              </svg>
            </div>
          </div>
      </template>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'

import ChatMessage from '@/components/homeiz/messanger/message/ChatMessage.vue'
import ChatMessageMy from '@/components/homeiz/messanger/message/ChatMessageMy.vue'
import MessageDate from '../message/MessageDate.vue'

export default {
  name: 'ChatContent',
  components: {
    ChatMessage,
    ChatMessageMy,
    MessageDate
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    messages: {
      type: Array,
      required: true
    }
  },
  updated () {
    this.$nextTick(() => {
      const el = document.getElementById('last-message')
      if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'instant', block: 'end' })
      }
    })
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('messanger', {
      messangerTheme: messangerGetters.GET_THEME
    })
  },
  methods: {
    scrollDown () {
      const el = document.getElementById('last-message')
      if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'instant', block: 'end' })
      }
    }
  }
}
</script>

<style scoped>
.loader-dots {
  color: rgb(255, 255, 255);
  width: 1px;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 25px 0 0 3px, 38px 0 0 1px, 50px 0 0 0;
  transform: translateX(-38px);
  animation: l21 .5s infinite alternate linear;
}

@keyframes l21 {
  50%  {box-shadow: 25px 0 0 0, 38px 0 0 3px, 50px 0 0 1px}
  100% {box-shadow: 25px 0 0 1px  , 38px 0 0 0, 50px 0 0 3px}
}

@keyframes l22 {
  50%  {box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px}
  100% {box-shadow: 19px 0 0 0  , 38px 0 0 3px, 57px 0 0 7px}
}
</style>
