<template>
  <main class="text-danger" @click="deleteChat">
    <i class="fa fa-trash-o mr-0" aria-hidden="true"></i>
    {{ title }}
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'

export default {
  name: 'DeleteChat',
  props: {
    chat: {
      type: Object,
      required: true
    }
  },
  computed: {
    title () {
      return this.chat.isGroup ? 'Delete group' : 'Delete chat'
    }
  },
  methods: {
    ...mapActions('messanger', {
      deleteChatById: messangerActions.DELETE_CHAT_BY_ID
    }),
    async deleteChat () {
      // Using HTML string
      const title = `<p>Are you sure you want to delete ${this.chat.isGroup ? 'group' : 'chat with'} "${this.chat.info.title}"?</p> <p>This action cannot be undone.</p>`
      const h = this.$createElement
      const titleVNode = h('div', { domProps: { innerHTML: title } })
      // const title = `Are you sure you want to delete "${this.chat.info.title}"? This action cannot be undone.`
      const response = await this.$bvModal.msgBoxConfirm(
        [titleVNode],
        {
          title: '',
          okTitle: 'Delete',
          okVariant: 'uppercase-warning',
          cancelTitle: 'Cancel',
          cancelVariant: 'uppercase'
        }
      )
      if (response) {
        this.deleteChatById(this.chat.id)
      }
    }
  }
}
</script>
