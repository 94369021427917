<template>
  <main @click="blockChat">
    <i class="fa fa-ban mr-0" aria-hidden="true"></i>
    {{ title }}
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'

export default {
  name: 'DeleteChat',
  props: {
    chat: {
      type: Object,
      required: true
    }
  },
  computed: {
    title () {
      return this.chat.isGroup ? 'Block group' : 'Block user'
    }
  },
  methods: {
    ...mapActions('messanger', {
      blockChatById: messangerActions.BLOCK_CHAT_BY_ID
    }),
    async blockChat () {
      // Using HTML string
      const h = this.$createElement
      const html = `<h4 class="mb-2">Block messages</h4><ul class="block-list"><li>Your Metwitt account won't receive messages from ${this.chat.info.title} Metwitt account.</li><li>This account won't be blocked on Metwitt. You may still be able to see ${this.chat.info.title} tweets, comments and reactions on Metwitt.</li><li>If you're in shared groups with this account, you'll still be able to see and communicate with each other. You can leave groups or rooms anytime.</li></ul>`
      const titleVNode = h('div', { domProps: { innerHTML: html } })
      // const title = `Are you sure you want to delete "${this.chat.info.title}"? This action cannot be undone.`
      const response = await this.$bvModal.msgBoxConfirm(
        [titleVNode],
        {
          title: `Block ${this.chat.info.title}?`,
          okTitle: 'Block',
          okVariant: 'uppercase-warning',
          cancelTitle: 'Cancel',
          cancelVariant: 'uppercase'
        }
      )
      if (response) {
        this.blockChatById(this.chat.id)
      }
    }
  }
}
</script>
