<template>
  <div class="d-flex align-items-center" @click="selectMessageHandler">
    <i class="ri-checkbox-circle-line"></i>
    <div class="ml-2">{{ title }}</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'

export default {
  name: 'SelectMessage',
  components: { },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('messanger', {
      selectMessage: messangerActions.SELECT_MESSAGE
    }),
    async selectMessageHandler () {
      await this.selectMessage(this.id)
    }
  }
}
</script>
