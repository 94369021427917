<template>
    <div class="d-flex align-items-center text-danger" @click="deleteMessage">
      <i class="ri-delete-bin-6-line"></i>
      <div class="ml-2">Delete</div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'

export default {
  name: 'DeleteMessage',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions('messanger', {
      deleteMessageById: messangerActions.DELETE_MESSAGE_BY_ID
    }),
    async deleteMessage () {
      const h = this.$createElement
      const titleVNode = h('div', { domProps: { innerHTML: `<p>Do you wnat to delete this message?</p> <p>This action cannot be undone.</p> <p><input id="all" type="checkbox" name="all" /><label class="ml-2">Delete for everyone.</label></p>` } })
      const response = await this.$bvModal.msgBoxConfirm(
        [titleVNode],
        {
          title: '',
          okTitle: 'Delete',
          okVariant: 'uppercase-warning',
          cancelTitle: 'Cancel',
          cancelVariant: 'uppercase'
        }
      )
      if (response) {
        await this.deleteMessageById(this.id)
      }
    }
  }
}
</script>
