<template>
  <b-skeleton-wrapper class="w-100" :loading="loading">
    <template #loading>
      <div class="d-flex align-items-center">
        <b-skeleton class="w-100" type="input"></b-skeleton>
      </div>
    </template>
    <div>
      <div v-if="editMessage" class="d-flex align-items-center">
        <div class="chat-attagement d-flex mr-4 text-success">
          <i class="ri-edit-fill"></i>
        </div>
        <div class="ml-2 font-size-12">
          <div class="text-bold">Edit message</div>
          <div>{{ editMessagePreview }}</div>
        </div>
        <div class="ml-3 text-success cursor-pointer" @click="discardEditMessage">
          <i class="ri-close-line"></i>
        </div>
      </div>
      <div v-if="replyMessage" class="d-flex align-items-center">
        <div class="chat-attagement d-flex mr-4 text-success">
          <i class="ri-reply-line"></i>
        </div>
        <div class="ml-2 font-size-12">
          <div class="text-bold">Reply to {{ replyMessage.creator.fullName }}</div>
          <div>{{ replyMessagePreview }}</div>
        </div>
        <div class="ml-3 text-success cursor-pointer" @click="discardReplyMessage">
          <i class="ri-close-line"></i>
        </div>
      </div>
      <form class="d-flex align-items-center" action="javascript:void(0);">
        <photo-uploader :files="files" @mediaUploaded="onMediaUploaded" />
        <video-uploader :files="files" @mediaUploaded="onMediaUploaded" />
        <file-uploader :files="files" @mediaUploaded="onMediaUploaded" />
        <!-- <input
          ref="textarea"
          type="text"
          v-on:keyup.enter="submitMessage"
          v-model="chatMessage"
          class="form-control mr-3 border-none font-14"
          placeholder="Write a message..."
        > -->
        <div class="w-90">
          <Editor
            api-key="n3agkh4msoxv3sg4xmfu0t3io04hbpj0d2k3o765nusl6j06"
            model-events="input"
            :init="tinymce"
            placeholder="Write a message..."
            v-model="chatMessage"
          />
          <!-- <VueEditor
            id="message"
            :editorOptions="editorOptions"
            v-model="chatMessage"
            placeholder="Write a message..."
          /> -->
        </div>
        <button :disabled="!chatMessage" @click="submitMessage" type="button" class="btn btn-chat-send d-flex align-items-center p-2">
          <img src="@/assets/images/chat/send-message.svg" alt="Send" class="img-fluid svg">
        </button>
      </form>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
// import { VueEditor } from 'vue2-editor'
import Editor from '@tinymce/tinymce-vue'
import { mapActions, mapGetters } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import ChatMessage from '@/Model/Messanger/ChatMessage'
import PhotoUploader from '@/components/homeiz/messanger/PhotoUploader.vue'
import FileUploader from '@/components/homeiz/messanger/FileUploader.vue'
import VideoUploader from '@/components/homeiz/messanger/VideoUploader.vue'

export default {
  components: { PhotoUploader, FileUploader, VideoUploader, Editor },
  name: 'ChatWindowFooter',
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      files: [],
      tinymce: {
        id: 'chat-message',
        inline: true,
        convert_unsafe_embeds: false,
        extended_valid_elements: 'span[class|data-text|alt|style]',
        toolbar_mode: 'sliding',
        plugins: 'emoticons',
        emoticons_database: 'emojiimages',
        emoticons_images_url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/15.1.0/72x72',
        content_style: 'body { font-size: 14px; line-height: 28px; margin: 6px;} .emoji-type-image { width: 28px; height: 28px; display: inline-block; background-size: 6100%; background-image: url("https://unpkg.com/emoji-datasource-apple@14.0.0/img/apple/sheets-256/64.png") }',
        style_formats: [
          'span'
        ],
        force_br_newlines: false,
        force_p_newlines: false,
        forced_root_block: '',
        toolbar: false,
        menubar: false,
        statusbar: false,
        height: '50',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject(new Error('See docs to implement AI Assistant')))
      },
      editorOptions: {
        modules: {
          toolbar: false
          // short_name_emoji: true
        }
      }
    }
  },
  computed: {
    ...mapGetters('messanger', {
      editMessage: messangerGetters.GET_EDIT_MESSAGE_STATE,
      replyMessage: messangerGetters.GET_REPLY_MESSAGE_STATE,
      draftMessage: messangerGetters.GET_DRAFT_MESSAGE,
      draftHtmlMessage: messangerGetters.GET_DRAFT_HTML_MESSAGE,
      activeChatId: messangerGetters.GET_ACTIVE_CHAT_ID
    }),
    editMessagePreview () {
      if (this.editMessage.message.length) {
        return this.editMessage.messagePreview
      } else {
        return ''
      }
    },
    replyMessagePreview () {
      if (this.replyMessage.message.length) {
        return this.replyMessage.messagePreview
      } else {
        return ''
      }
    },
    chatMessage: {
      get () {
        return this.draftMessage[this.activeChatId] ? this.draftMessage[this.activeChatId].message : ''
      },
      set (newValue) {
        this.updateDraftMessage(newValue)
        // this.updateDraftHtmlMessage(newValue)
      }
    }
  },
  methods: {
    ...mapActions('messanger', {
      sendMessage: messangerActions.SEND_MESSAGE,
      discardEditMessage: messangerActions.DISCARD_EDIT_MESSAGE,
      discardReplyMessage: messangerActions.DISCARD_REPLY_MESSAGE,
      updateMessage: messangerActions.UPDATE_MESSAGE_BY_ID,
      updateDraftMessage: messangerActions.UPDATE_DRAFT_MESSAGE
    }),
    async submitMessage () {
      if (this.editMessage) {
        await this.updateMessage({ ...this.editMessage, message: this.chatMessage })
        this.discardEditMessage()
      } else if (this.replyMessage) {
        const message = new ChatMessage({ message: this.chatMessage, parent: this.replyMessage })
        await this.sendMessage(message)
        this.discardReplyMessage()
      } else {
        const message = new ChatMessage({ message: this.chatMessage })
        // const message = new ChatMessage({ message: this.draftHtmlMessage })
        await this.sendMessage(message)
      }

      this.chatMessage = null
    },
    onMediaUploaded () {
      this.files = []
    },
    chatMessageUpdate () {
    }
  }
}
</script>

<style>
.w-90 {
  width: 90%;
}

#message {
  max-width: 100%;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-editor {
  min-height: 30px !important;
  font-size: 20px !important;
  border: none;
}
</style>
