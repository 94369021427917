<template>
  <main>
    <chat-image v-if="files[0]['type'].split('/')[0] === 'image'" :images="files"/>
    <chat-video v-else-if="files[0]['type'].split('/')[0] === 'video'" :video="files"/>
    <chat-file v-else :files="files"/>
  </main>
</template>

<script>
import ChatImage from './ChatImage.vue'
import ChatVideo from './ChatVideo.vue'
import ChatFile from './ChatFile.vue'

export default {
  name: 'ChatMedia',
  components: { ChatImage, ChatVideo, ChatFile },
  props: {
    files: {
      type: Array
    }
  }
}
</script>
