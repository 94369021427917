<template>
  <main @click="openCreateGroupChat">
    <div>
      <i class="ri-group-line mr-0" aria-hidden="true"></i>
      Create a group chat
    </div>
    <b-modal
      id="create-group-chat-modal"
      size="md"
      v-model="createGroupChatState"
      @close="closeCreateGroupChat"
      no-close-on-esc
      no-close-on-backdrop
      content-class="rounded"
      no-fade
      scrollable
    >
      <template #modal-title>
        <div class="d-flex align-items-center justify-content-center">
          <i class="ri-group-line font-size-20 mr-2" aria-hidden="true"></i> Create a group chat
        </div>
      </template>
      <div class="pl-4 pr-4" v-if="step === 1">
        <div class="d-flex flex-wrap align-items-center justify-content-center mt-2">
          <label class="w-20 text-center cursor-pointer" for="logo">
            <img :src="groupChatImage" class="rounded-circle group-image-preview" :alt="group.info.title">
          </label>
          <input
            :id="`logo`"
            type="file"
            accept="image/*"
            :multiple="false"
            :max="1"
            v-show="false"
            @change="uploadFile"
          />
          <input type="text" v-model="group.info.title" class="simple-input font-14 w-75 ml-2" placeholder="Group name">
        </div>
        <div class="pl-3 pr-3">
          <h5>Group type</h5>
          <b-form-group class="">
              <b-form-radio-group
                id="radio-group-type"
                v-model="groupType"
                name="radio-sub-component"
              >
              <div class="d-flex flex-wrap justify-content-between align-items-center pb-3">
                <div class="d-flex align-items-center w-100">
                  <b-form-radio class="mr-1" :value="false"/>
                  <div><b>Public group</b></div>
                  <div class="icon font-size-20">
                    <img src="@/assets/images/icon/public-feed.svg" alt="Public group" class="img-fluid svg"/>
                  </div>
                </div>
                <div class="d-flex w-100 mb-2">
                  <div class="data">
                    <div class="mb-0 font-size-10-pt">Anyone can find the group in search and join, chat history is available to everybody</div>
                  </div>
                </div>
                <div class="d-flex align-items-center w-100">
                  <b-form-radio class="mr-1" :value="true"/>
                  <div><b>Private group</b></div>
                  <div class="icon font-size-22">
                    <img src="@/assets/images/icon/private-group.svg" alt="Private group" class="img-fluid private-group svg"/>
                  </div>
                </div>
                <div class="d-flex w-100">
                  <div class="data">
                    <div class="mb-0 font-size-10-pt">People can only join if they are added or have an invite link</div>
                  </div>
                </div>
              </div>
              </b-form-radio-group>
              <div class="w-100 mt-4">
                <form-input-limited input-class="simple-input" v-model="group.info.description" :limit="50" placeholder="Description"/>
              </div>
          </b-form-group>
        </div>
      </div>
      <div v-else>
        <div>
          <div class="d-flex justify-content-between mb-4">
            <form action="#" class="searchbox search-box-user d-flex position-relative align-items-center w-100">
              <input
                v-model="search"
                type="text"
                class="form-control router-link-active search-input"
                placeholder="Search for contacts..."
              >
              <div class="comment-attagement d-flex">
                <b-link href="javascript:void(0);">
                  <i class="ri-search-line mr-2"></i>
                </b-link>
              </div>
            </form>
          </div>
          <div v-if="members.length">
            <h6 class="text-uppercase">Members</h6>
            <div class="d-flex flex-wrap tagged-scrollable">
              <div class="tag-people-wrapper mr-2 mb-2" v-for="(item, index) in members" :key="index">
                <div>{{ item.fullName }}</div>
                <div class="ml-2 cursor-pointer" @click="removeMember(item.id)">X</div>
              </div>
            </div>
          </div>
          <div>
            <h6 class="text-uppercase mb-2">Suggestions</h6>
          </div>
          <div class="scrollable members-search-results">
            <div
              v-for="(item, index) in filteredUsers"
              :key="index"
              class="media cursor-pointer align-items-center mb-4"
              @click="addMember(item)"
            >
              <div class="iq-profile-avatar status-online">
                <img class="rounded-circle avatar-50" :src="item.avatar" :alt="item.fullName">
              </div>
              <div class="media-body ml-3">
                <h6 class="mb-0">{{ item.fullName }}</h6>
                <p class="mb-0">{{ item.time }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div v-if="step === 1">
          <div class="w-100 d-flex flex-wrap justify-content-end">
            <b-button class="text-center" @click="cancel" variant="uppercase">Cancel</b-button>
            <b-button class="text-center" :disabled="!group.info.title" @click="nextStep" variant="uppercase">Next</b-button>
          </div>
        </div>
        <div v-else class="w-100 d-flex flex-wrap justify-content-end">
          <b-button class="text-center" @click="cancel" variant="uppercase">Cancel</b-button>
          <b-button class="text-center" @click="create" variant="uppercase">Create</b-button>
        </div>
      </template>
    </b-modal>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import * as messangerActions from '@/store/modules/messanger/types/actions'
import * as userGetters from '@/store/modules/chat-panel/types/getters'
import { MAX_IMAGE_FILE_SIZE } from '@/config/file.js'
import Chat from '@/Model/Messanger/Chat'
import FormInputLimited from '../common/form/FormInputLimited.vue'

export default {
  components: { FormInputLimited },
  name: 'CreateGroupChat',
  data () {
    return {
      defaultAvatar: require('@/assets/images/user/camera2.svg'),
      group: new Chat({ isGroup: true }),
      groupImage: null,
      groupType: null,
      search: '',
      members: [],
      step: 1
    }
  },
  computed: {
    ...mapGetters('messanger', {
      createGroupChatState: messangerGetters.CREATE_GROUP_CHAT_STATE
    }),
    ...mapGetters('chatPanel', {
      users: userGetters.GET_BUSINESS_USERS
    }),
    groupChatImage () {
      return this.groupImage ? this.groupImage : this.defaultAvatar
    },
    filteredUsers () {
      return Object.values(this.users).filter(item => {
        return item.fullName.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    ...mapActions('messanger', {
      openCreateGroupChat: messangerActions.OPEN_CREATE_GROUP_CHAT,
      closeCreateGroupChat: messangerActions.CLOSE_CREATE_GROUP_CHAT,
      createChat: messangerActions.CREATE_CHAT
    }),
    uploadFile (event) {
      event.target.files.forEach(file => {
        this.addFile(file)
      })
    },
    addFile (file) {
      if (file.size < MAX_IMAGE_FILE_SIZE) {
        file.preview = URL.createObjectURL(file)
        this.group.info.image = file.preview
        this.groupImage = file.preview
      } else {
        alert('File is too big!')
      }
    },
    addMember (user) {
      this.members.push(user)
    },
    removeMember (id) {
      this.members = this.members.filter(function (item) {
        return item.id !== id
      })
    },
    cancel () {
      this.step = 1
      this.group = new Chat({ isGroup: true })
      this.closeCreateGroupChat()
    },
    nextStep () {
      this.step = 2
    },
    async create () {
      this.group.members = this.members
      this.group.isPrivate = this.groupType
      await this.createChat(this.group)
      this.closeCreateGroupChat()
      this.group = new Chat({ isGroup: true })
      this.step = 1
      this.members = []
    }
  }
}
</script>
