<template>
  <b-col lg="12">
    <div class="chat-start">
      <div class="chat-start">
        <span class="iq-start-icon text-primary mb-3">
          <img src="@/assets/images/icon/chat-icon-for-messanger.svg" alt="Select a chat to start messaging"
            class="img-fluid" />
        </span>
        <h5 id="chat-start" class="mt-4 text-black">Select a chat to start messaging</h5>
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'ChatHomePage'
}
</script>
