<template>
  <main @click="openEditGroupChat">
    <div>
      <i class="ri-user-add-line"/>
      Add members
    </div>
    <b-modal
      id="add-members-chat-modal"
      size="md"
      v-model="editGroupChatState"
      @close="closeEditGroupChat"
      no-close-on-esc
      no-close-on-backdrop
      content-class="rounded"
      no-fade
      scrollable
    >
      <template #modal-title>
        <div class="d-flex align-items-center justify-content-center">
          <i class="ri-user-add-line"/> Add members
        </div>
      </template>
      <div class="pl-4 pr-4" v-if="dataLoaded">
        <div class="d-flex justify-content-between mb-4">
            <form action="#" class="searchbox search-box-user d-flex position-relative align-items-center w-100">
              <input
                v-model="search"
                type="text"
                class="form-control router-link-active search-input"
                placeholder="Search for contacts..."
              >
              <div class="comment-attagement d-flex">
                <b-link href="javascript:void(0);">
                  <i class="ri-search-line mr-2"></i>
                </b-link>
              </div>
            </form>
          </div>
        <div>
          <h6 class="text-uppercase mb-2">Suggestions</h6>
        </div>
        <div class="scrollable">
          <div
            v-for="(item, index) in filteredUsers"
            :key="index"
            class="media cursor-pointer align-items-center mb-4"
            @click="addMember(item)"
          >
            <div class="iq-profile-avatar status-online">
              <img class="rounded-circle avatar-50" :src="item.avatar" :alt="item.fullName">
            </div>
            <div class="media-body ml-3">
              <h6 class="mb-0">{{ item.fullName }}</h6>
              <p class="mb-0">{{ item.time }}</p>
            </div>
          </div>
        </div>
        <div class="">
          <div class="d-flex align-tems-center justify-content-between mb-2">
            <h5><i class="ri-group-line"></i> Members</h5>
            <div class="text-blue">{{ form.members.length }}</div>
          </div>
          <div
            v-for="(item, index) in form.members"
            :key="index"
            class="media cursor-pointer align-items-center mb-2"
          >
            <div class="iq-profile-avatar status-online">
              <img class="rounded-circle avatar-50" :src="item.avatar" :alt="item.fullName">
            </div>
            <div class="d-flex w-100 justify-conten-between align-items-center">
              <div class="ml-3 w-70 d-flex flex-wrap align-items-center">
                <h6 class="mb-0 font-10-pt text-bold mr-2">{{ item.fullName }}</h6>
                <div v-if="administrator(item)" class="font-10-pt text-blue d-flex align-items-center"><i class="ri-star-fill mr-1"></i> <b-badge variant="main">Admin</b-badge></div>
                <div class="font-11 lh-11 text-left w-100">last seen {{ $d(new Date()) }}</div>
              </div>
              <div class="w-30 text-right text-red" @click="removeMember(item)">Remove</div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex flex-wrap justify-content-end">
          <b-button class="text-center" @click="cancel" variant="uppercase">Cancel</b-button>
          <b-button class="text-center" @click="save" variant="uppercase">Save</b-button>
        </div>
      </template>
    </b-modal>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import * as messangerActions from '@/store/modules/messanger/types/actions'
import * as userGetters from '@/store/modules/chat-panel/types/getters'
import Chat from '@/Model/Messanger/Chat'

export default {
  components: { },
  name: 'AddMembersGroupChat',
  async mounted () {
    await this.onLoad()
  },
  data () {
    return {
      dataLoaded: false,
      form: {},
      search: ''
    }
  },
  computed: {
    ...mapGetters('chatPanel', {
      users: userGetters.GET_GROUP_USERS
    }),
    ...mapGetters('messanger', {
      editGroupChatState: messangerGetters.EDIT_GROUP_MEMBERS_CHAT_STATE,
      group: messangerGetters.GET_ACTIVE_CHAT
    }),
    filteredUsers () {
      return Object.values(this.users).filter(item => {
        return item.fullName.toLowerCase().includes(this.search.toLowerCase()) && !this.form.members.find(obj => obj.id === item.id)
      })
    }
  },
  methods: {
    ...mapActions('messanger', {
      openEditGroupChat: messangerActions.OPEN_EDIT_MEMBERS_GROUP_CHAT,
      closeEditGroupChat: messangerActions.CLOSE_EDIT_MEMBERS_GROUP_CHAT,
      saveChat: messangerActions.SAVE_CHAT
    }),
    addMember (user) {
      this.form.members.push(user)
    },
    removeMember (user) {
      const item = this.form.members.find(item => item.id === user.id)
      const index = this.form.members.indexOf(item)
      this.form.members.splice(index, 1)
    },
    administrator (user) {
      if (this.group.info.administrators && this.group.info.administrators.length) {
        return this.group.info.administrators.find(item => item.id === user.id)
      }
      return false
    },
    cancel () {
      this.closeEditGroupChat()
    },
    async save () {
      const response = await this.saveChat(this.form)
      this.closeEditGroupChat()
      this.form = new Chat({ ...response, info: { ...response.info } })
    },
    async onLoad () {
      this.form = new Chat({ ...this.group, info: { ...this.group.info } })
      this.groupImage = this.group.info.image
      this.dataLoaded = true
    }
  }
}
</script>
