<template>
  <main @click="openEditGroupChat">
    <div>
      <i class="ri-group-line mr-0" aria-hidden="true"></i>
      Manage group
    </div>
    <b-modal
      id="create-group-chat-modal"
      size="md"
      v-model="editGroupChatState"
      @close="closeEditGroupChat"
      no-close-on-esc
      no-close-on-backdrop
      content-class="rounded"
      no-fade
      scrollable
    >
      <template #modal-title>
        <div class="d-flex align-items-center justify-content-center">
          <i class="ri-group-line font-size-20 mr-2" aria-hidden="true"></i> Edit group
        </div>
      </template>
      <div class="pl-4 pr-4" v-if="dataLoaded">
        <div class="d-flex flex-wrap align-items-center justify-content-center mt-2">
          <label class="w-20 text-center cursor-pointer" for="logo">
            <img :src="groupChatImage" class="rounded-circle group-image-preview" :alt="form.info.title">
          </label>
          <input
            :id="`logo`"
            type="file"
            accept="image/*"
            :multiple="false"
            :max="1"
            v-show="false"
            @change="uploadFile"
          />
          <input type="text" v-model="form.info.title" class="simple-input font-14 w-75 ml-2" placeholder="Group name">
        </div>
        <div class="pl-3 pr-3">
          <h5>Group type</h5>
          <b-form-group class="">
              <b-form-radio-group
                id="radio-group-type"
                v-model="form.isPrivate"
                name="radio-sub-component"
              >
              <div class="d-flex flex-wrap justify-content-between align-items-center pb-3">
                <div class="d-flex align-items-center w-100">
                  <b-form-radio class="mr-1" :value="false"/>
                  <div><b>Public group</b></div>
                  <div class="icon font-size-20">
                    <img src="@/assets/images/icon/public-feed.svg" alt="Public group" class="img-fluid svg"/>
                  </div>
                </div>
                <div class="d-flex w-100 mb-2">
                  <div class="data">
                    <div class="mb-0 font-size-10-pt">Anyone can find the group in search and join, chat history is available to everybody</div>
                  </div>
                </div>
                <div class="d-flex align-items-center w-100">
                  <b-form-radio class="mr-1" :value="true"/>
                  <div><b>Private group</b></div>
                  <div class="icon font-size-22">
                    <img src="@/assets/images/icon/private-group.svg" alt="Private group" class="img-fluid private-group svg"/>
                  </div>
                </div>
                <div class="d-flex w-100">
                  <div class="data">
                    <div class="mb-0 font-size-10-pt">People can only join if they are added or have an invite link</div>
                  </div>
                </div>
              </div>
              </b-form-radio-group>
              <div class="w-100 mt-4">
                <form-input-limited input-class="simple-input" v-model="form.info.description" :limit="50" placeholder="Description"/>
              </div>
          </b-form-group>
        </div>
        <div class="pl-3 pr-3">
          <div class="d-flex align-tems-center justify-content-between mb-2">
            <h5><i class="ri-group-line"></i> Members</h5>
            <div class="text-blue">{{ form.members.length }}</div>
          </div>
          <div
            v-for="(item, index) in form.members"
            :key="index"
            class="media cursor-pointer align-items-center mb-2"
          >
            <div class="iq-profile-avatar status-online">
              <img class="rounded-circle avatar-50" :src="item.avatar" :alt="item.fullName">
            </div>
            <div class="d-flex w-100 justify-conten-between align-items-center">
              <div class="ml-3 w-70 d-flex flex-wrap align-items-center">
                <h6 class="mb-0 font-10-pt text-bold mr-2">{{ item.fullName }}</h6>
                <div v-if="administrator(item)" class="font-10-pt text-blue d-flex align-items-center"><i class="ri-star-fill mr-1"></i> <b-badge variant="main">Admin</b-badge></div>
                <div class="font-11 lh-11 text-left w-100">last seen {{ $d(new Date()) }}</div>
              </div>
              <div class="w-30 text-right text-red" @click="removeMember(item)">Remove</div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex flex-wrap justify-content-end">
          <b-button class="text-center" @click="cancel" variant="uppercase">Cancel</b-button>
          <b-button class="text-center" @click="save" variant="uppercase">Save</b-button>
        </div>
      </template>
    </b-modal>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import * as messangerActions from '@/store/modules/messanger/types/actions'
import { MAX_IMAGE_FILE_SIZE } from '@/config/file.js'
import Chat from '@/Model/Messanger/Chat'
import FormInputLimited from '../common/form/FormInputLimited.vue'

export default {
  components: { FormInputLimited },
  name: 'ManageGroupChat',
  async mounted () {
    await this.onLoad()
  },
  data () {
    return {
      dataLoaded: false,
      defaultAvatar: require('@/assets/images/user/camera2.svg'),
      groupImage: null,
      groupType: null,
      form: {},
      search: ''
    }
  },
  computed: {
    ...mapGetters('messanger', {
      editGroupChatState: messangerGetters.EDIT_GROUP_CHAT_STATE,
      group: messangerGetters.GET_ACTIVE_CHAT
    }),
    groupChatImage () {
      return this.groupImage ? this.groupImage : this.defaultAvatar
    }
  },
  methods: {
    ...mapActions('messanger', {
      openEditGroupChat: messangerActions.OPEN_EDIT_GROUP_CHAT,
      closeEditGroupChat: messangerActions.CLOSE_EDIT_GROUP_CHAT,
      saveChat: messangerActions.SAVE_CHAT
    }),
    removeMember (user) {
      const item = this.form.members.find(item => item.id === user.id)
      const index = this.form.members.indexOf(item)
      this.form.members.splice(index, 1)
    },
    administrator (user) {
      if (this.group.info.administrators && this.group.info.administrators.length) {
        return this.group.info.administrators.find(item => item.id === user.id)
      }
      return false
    },
    uploadFile (event) {
      event.target.files.forEach(file => {
        this.addFile(file)
      })
    },
    addFile (file) {
      if (file.size < MAX_IMAGE_FILE_SIZE) {
        file.preview = URL.createObjectURL(file)
        this.form.info.image = file.preview
        this.groupImage = file.preview
      } else {
        alert('File is too big!')
      }
    },
    cancel () {
      this.closeEditGroupChat()
    },
    async save () {
      const response = await this.saveChat(this.form)
      this.closeEditGroupChat()
      this.form = new Chat({ ...response, info: { ...response.info } })
    },
    async onLoad () {
      this.form = new Chat({ ...this.group, info: { ...this.group.info } })
      this.groupImage = this.group.info.image
      this.dataLoaded = true
    }
  }
}
</script>
