<template>
  <div>
    <b-row>
      <b-col sm="12">
        <iq-card body-class="chat-page p-0" class="mb-0">
          <template v-slot:body>
            <div class="chat-data-block">
              <b-row>
                <b-col lg="3" class="chat-data-left scroller pl-0 pr-0">
                  <div class="chat-search d-flex justify-content-between align-items-center pl-3">
                    <chat-search />
                    <chat-main-menu />
                  </div>
                  <div class="chat-sidebar-channel scroller pl-1">
                    <chat-list :chat-list="chatListFiltered" />
                  </div>
                </b-col>
                <div class="col-lg-9 chat-data p-0 chat-data-right border-right">
                  <component v-bind:is="chatMainWindowState"/>
                </div>
              </b-row>
            </div>
            <forward-message-modal :chat-list="chatListFiltered" />
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { socialvue } from '@/config/pluginInit'
import { mapGetters, mapActions } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import * as messangerActions from '@/store/modules/messanger/types/actions'

import ChatSearch from '@/components/homeiz/messanger/ChatSearch.vue'
import ChatMainMenu from '@/components/homeiz/messanger/ChatMainMenu.vue'
import ChatList from '@/components/homeiz/messanger/ChatList.vue'
import ChatHomePage from '@/components/homeiz/messanger/ChatHomePage.vue'
import ChatWindow from '@/components/homeiz/messanger/ChatWindow.vue'
import ForwardMessageModal from '@/components/homeiz/messanger/message/ForwardMessageModal.vue'

export default {
  name: 'MessangerPage',
  components: {
    ChatSearch,
    ChatMainMenu,
    ChatList,
    ChatHomePage,
    ChatWindow,
    ForwardMessageModal
  },
  mounted () {
    socialvue.index()
    this.onLoad()
  },
  data () {
    return { }
  },
  computed: {
    ...mapGetters('messanger', {
      activeChatId: messangerGetters.GET_ACTIVE_CHAT_ID,
      chatList: messangerGetters.GET_CHAT_LIST,
      chatListMap: messangerGetters.GET_CHAT_LIST_MAP,
      searchString: messangerGetters.GET_SEARCH_STRING
    }),
    chatMainWindowState () {
      if (this.activeChatId) {
        return ChatWindow
      } else {
        return ChatHomePage
      }
    },
    chatListSorted () {
      return this.chatListMap.map(id => this.chatList[id])
    },
    chatListFiltered () {
      return this.chatListSorted.filter(item => {
        return item.info.title.toLowerCase().includes(this.searchString.toLowerCase())
      })
    }
  },
  methods: {
    ...mapActions('messanger', {
      fecthChatList: messangerActions.FETCH_CHAT_LIST
    }),
    async onLoad () {
      await this.fecthChatList()
    }
  }
}
</script>
<style lang="scss">
.remove-toggle::after {
  content: unset;
}
</style>
