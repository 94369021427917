<template>
  <div class="d-flex align-items-center" @click="saveAs">
    <i class="ri-bookmark-line"></i>
    <div class="ml-2">Save as</div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'

export default {
  name: 'SaveAs',
  props: {
    media: {
      type: Array,
      required: true
    }
  },
  methods: {
    async saveAs () {
      try {
        this.media.forEach((media) => {
          saveAs(media.preview, media.name)
        })
      } catch ($e) {
        console.log($e)
      }
    }
  }
}
</script>
