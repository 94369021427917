<template>
  <div>
    <div>
      <div class="chat-attagement d-flex">
        <label ref="addFile" for="photo-attachment" @click="selectFile">
          <div class="cursor-pointer"><img src="@/assets/images/icon/messanger-photo.svg" alt="Attach photo" title="Attach photo"
              class="img-fluid svg mr-4"></div>
        </label>
      </div>
      <input id="photo-attachment" type="file" name="chat-attachment" class="d-none" :accept="accept"
        :multiple="multiple" :max="limit" @change="uploadFile">
    </div>
    <b-modal :id="`media-modal-photo`" size="md-400" scrollable title="" hide-header content-class="rounded" body-class="bg-white"
      no-fade no-close-on-backdrop no-close-on-esc v-model="showModal">
      <h5>{{ files.length }} selected</h5>
      <div class="mt-2">
        <messanger-photos :images="files" @replace-image="onReplaceHandeler"/>
      </div>
      <div class="mt-4 d-flex">
        <input type="text" v-model="caption" class="font-20 w-75 ml-2 image-caption" placeholder="Caption">
        <div class="rounded p-2 pointer cursor-pointer text-center ml-4" title="Emoji">
          <img src="@/assets/images/icon/feeling.svg" alt="emoji" class="img-fluid svg">
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <b-button variant="uppercase" @click="onAddHandled">
              <img src="@/assets/images/icon/add-picture.svg" alt="Add" class="img-fluid svg" />
            </b-button>
          </div>
          <div>
            <b-button variant="uppercase" @click="onCancelHandled">Cancel</b-button>
            <b-button :disabled="!files.length" variant="uppercase" @click="onSendHandled">Send</b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'
import MessangerPhotos from './MessangerPhotos.vue'
import ChatMessage from '@/Model/Messanger/ChatMessage'
import * as messageType from '@/config/messanger/messageType'

export default {
  components: { MessangerPhotos },
  name: 'PhotoUploader',
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    limit: {
      type: Number,
      default: 15
    },
    sizeLimit: {
      type: Number,
      default: 10000000
    },
    files: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      caption: null,
      videoLoaded: false,
      selectedFile: 1,
      replace: false,
      state: false
    }
  },
  computed: {
    showModal () {
      return !!this.files.length && this.state
    }
  },
  methods: {
    ...mapActions('messanger', {
      sendMessage: messangerActions.SEND_MESSAGE
    }),
    selectFile () {
      this.state = true
    },
    uploadFile (event) {
      event.target.files.forEach(file => {
        this.addFile(file)
      })
    },
    addFile (file) {
      if (file.size < this.sizeLimit) {
        if (file['type'].split('/')[0] === 'image') {
          file.preview = URL.createObjectURL(file)
        }

        if (this.replace) {
          this.files[this.replace - 1] = file
          this.replace = false
          this.files = [ ...this.files ]
        } else {
          if (this.files.length < this.limit) {
            this.selectedFile = this.files.length + 1
            this.files.push(file)
          }
        }
      } else {
        alert('File is too big!')
      }
    },
    async onSendHandled () {
      const message = new ChatMessage({
        message: this.caption,
        type: messageType.IMAGE,
        media: this.files
      })

      await this.sendMessage(message)

      this.onCancelHandled()
    },
    onCancelHandled () {
      this.caption = ''
      this.state = false
      this.$emit('mediaUploaded')
    },
    onAddHandled () {
      this.$refs.addFile.click()
    },
    onCloseHandler () {
      // this.showModal = false
    },
    onReplaceHandeler (index) {
      this.replace = ++index
      this.$refs.addFile.click()
    }
  }
}
</script>
