<template>
  <main>
    <div class="w-100 d-flex">
      <div class="chat-window border-right">
        <div class="chat-head border-left">
          <chat-window-header :loading="loading" :info="activeChatInfo" :is-group="activeChat.isGroup" :members="activeChatMembers" :chat-id="activeChatId" />
        </div>
        <div class="d-flex">
          <chat-content :loading="loading" :messages="activeChatMessages"/>
        </div>
        <div class="chat-footer border-left border-right pb-1 pt-1 pr-3 pl-3 bg-white border-top">
          <chat-window-footer :loading="loading" />
        </div>
      </div>
      <chat-window-emoji />
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'

import ChatWindowHeader from './window/ChatWindowHeader.vue'
import ChatWindowFooter from './window/ChatWindowFooter.vue'
import ChatWindowEmoji from './window/ChatWindowEmoji.vue'
import ChatContent from './window/ChatContent.vue'

export default {
  name: 'ChatWindow',
  components: {
    ChatWindowHeader,
    ChatContent,
    ChatWindowFooter,
    ChatWindowEmoji
  },
  data () {
    return { }
  },
  computed: {
    ...mapGetters('messanger', {
      activeChat: messangerGetters.GET_ACTIVE_CHAT,
      activeChatId: messangerGetters.GET_ACTIVE_CHAT_ID,
      activeChatInfo: messangerGetters.GET_ACTIVE_CHAT_INFO,
      activeChatMessages: messangerGetters.GET_ACTIVE_CHAT_MESSAGES,
      activeChatMembers: messangerGetters.GET_ACTIVE_CHAT_MEMBERS,
      loading: messangerGetters.GET_CHAT_IS_LOADING
    })
  }
}
</script>
