<template>
  <div class="d-flex align-items-center">
    <div class="avatar mr-3">
      <img :src="item.avatar" :alt="item.title" class="avatar-50 rounded-circle">
      <span class="avatar-status" v-if="item.isActive">
        <i class="ri-checkbox-blank-circle-fill" :class="item.isActive ? 'text-success' :'text-warning'"/>
      </span>
    </div>
    <div class="chat-sidebar-name">
      <div class="d-flex align-items-center">
        <h6 class="mb-0 d-flex flex-wrap justify-content-start align-items-center">
          <i v-if="item.isGroup" class="ri-group-fill mr-1" />
          <div class="d-flex">
            {{ item.title }}
            <div v-if="item.isGroup" class="ml-1"> Group</div>
          </div>
          <div class="public-group-label ml-2 d-flex align-items-center" v-if="item.isGroup && !item.isPrivate"> - <img src="@/assets/images/icon/public-feed.svg" alt="Public group" class="svg mr-1"/> Public group </div>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  name: 'ChatItemShort',
  props: {
    item: { type: Object }
  }
}
</script>
