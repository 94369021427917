<template>
  <b-skeleton-wrapper class="w-50" :loading="loading">
    <template #loading>
      <b-skeleton type="avatar" class="rounded-circle"></b-skeleton>
    </template>
    <ToggleContent id="chat-user-detail-popup" bodyClass="chatuser-detail" center>
      <template v-slot:media>
        <img :src="data.image" class="rounded-circle group-info-image" :alt="data.username">
      </template>
      <template v-slot:title>
        <div class="user-name mt-4">
          <h4>{{ data.title }} <span v-if="activeChat.isGroup">Group</span></h4>
        </div>
        <div v-if="membersCount" class="user-desc d-flex justify-content-center align-items-center">
          <i class="ri-group-fill mr-1" /> {{ membersCount }} members
        </div>
        <div v-else class="user-desc">
          <p>{{ data.address }}</p>
          <b-button variant="text-blue">View profile</b-button>
        </div>
        <div v-if="activeChat.isGroup && !activeChat.isPrivate">
          <div class="public-group-label d-flex justify-content-center mt-1 align-items-center" >
            <img src="@/assets/images/icon/public-feed.svg" alt="Public group" class="svg mr-2"/> Public
          </div>
          <div class="public-group-label d-flex justify-content-center text-bold mt-1 align-items-center">
            <div><i class="ri-eye-line mr-1" /></div>
            <div>Visible anyone can join</div>
          </div>
        </div>
        <div class="mt-1" v-if="data.description">
          <div class="text-blue text-bold">About</div>
          <div class="text-bold">{{ data.description }}</div>
        </div>
      </template>
      <template v-slot:body>
          <div class="d-flex justify-content-between flex-wrap">
            <tab-nav
              :pills="true"
              class="iq-chat-ui d-flex justify-content-between align-items-center border-bottom pb-3"
              id="chat-info-data"
            >
              <template>
                <!-- <tab-nav-items v-if="membersCount" :id="`v-pills-members-id`" href="#v-pills-members" :ariaControls="`v-pills-members`" :active="true" role="tab">
                  <template v-slot:title>
                    {{ membersCount }} Members
                  </template>
                </tab-nav-items> -->
                <!-- <tab-nav-items v-if="!membersCount" :id="`v-pills-info-id`" href="#v-pills-info" :ariaControls="`v-pills-info`" :active="true" role="tab">
                  <template v-slot:title>
                    Info
                  </template>
                </tab-nav-items> -->
                <tab-nav-items :id="`v-pills-photos-id`" href="#v-pills-photos" :ariaControls="`v-pills-photos`" role="tab">
                  <template v-slot:title>
                    <div class="d-flex align-items-center">
                      <img src="@/assets/images/icon/group-photo.svg" alt="Photos" class="img-fluid svg">
                      449 photos
                    </div>
                  </template>
                </tab-nav-items>
                <tab-nav-items :id="`v-pills-videos-id`" href="#v-pills-videos" :ariaControls="`v-pills-videos`" role="tab">
                  <template v-slot:title>
                    <div class="d-flex align-items-center">
                      <img src="@/assets/images/icon/group-videos.svg" alt="Videos" class="img-fluid svg">
                      22 videos
                    </div>
                  </template>
                </tab-nav-items>
                <!-- <tab-nav-items :id="`v-pills-videos-id`" href="#v-pills-videos" :ariaControls="`v-pills-videos`"
                  role="tab">
                  <template v-slot:title>
                    22 videos
                  </template>
                </tab-nav-items> -->
                <tab-nav-items :id="`v-pills-files-id`" href="#v-pills-files" :ariaControls="`v-pills-files`" role="tab">
                  <template v-slot:title>
                    <div class="d-flex align-items-center">
                      <img src="@/assets/images/icon/group-files.svg" alt="Files" class="img-fluid svg">
                      570 files
                    </div>
                  </template>
                </tab-nav-items>
                <tab-nav-items :id="`v-pills-links-id`" href="#v-pills-links" :ariaControls="`v-pills-links`" role="tab">
                  <template v-slot:title>
                    <div class="d-flex align-items-center">
                      <img src="@/assets/images/icon/group-links.svg" alt="Links" class="img-fluid svg">
                      2056 links
                    </div>
                  </template>
                </tab-nav-items>
              </template>
            </tab-nav>
            <div class="w-100">
              <tab-content id="v-pills-tabContent2" class="pt-2 scroller chat-tab-scroller">
                <tab-content-item v-if="membersCount"  id="v-pills-members" :active="true" aria-labelled-by="v-pills-emoji">
                  <chat-members-list :members="members" />
                </tab-content-item>
                <!-- <tab-content-item v-else id="v-pills-info" :active="true" aria-labelled-by="v-pills-emoji">
                  <template>
                    <div class="row">
                      <div class="col-6 col-md-6 title">Username:</div>
                      <div class="col-6 col-md-6">@{{ data.username }} </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-6 col-md-6 title">Tel:</div>
                      <div class="col-6 col-md-6">{{ data.phone }}</div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-6 col-md-6 title">Web:</div>
                      <div class="col-6 col-md-6">{{ data.web }}</div>
                    </div>
                    <hr>
                  </template>
                </tab-content-item> -->
                <tab-content-item  id="v-pills-photos" aria-labelled-by="v-pills-emoji">
                  <template>
                    PHOTOS
                  </template>
                </tab-content-item>
                <tab-content-item id="v-pills-videos" aria-labelled-by="v-pills-emoji">
                  <template>
                    VIDEOS
                  </template>
                </tab-content-item>
                <tab-content-item id="v-pills-files" aria-labelled-by="v-pills-emoji">
                  <template>
                    FILES
                  </template>
                </tab-content-item>
                <tab-content-item id="v-pills-links" aria-labelled-by="v-pills-emoji">
                  <template>
                    LINKS
                  </template>
                </tab-content-item>
              </tab-content>
            </div>
          </div>
      </template>
    </ToggleContent>
  </b-skeleton-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import ToggleContent from '@/components/socialvue/Chat/ToggleContent'
import ChatMembersList from './ChatMembersList.vue'

export default {
  name: 'ChatInfo',
  components: {
    ToggleContent,
    ChatMembersList
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    members: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('messanger', {
      activeChat: messangerGetters.GET_ACTIVE_CHAT
    }),
    membersCount () {
      return Object.keys(this.members).length
    }
  }
}
</script>
