<template>
  <main class="w-100">
    <b-button
      :aria-label="buttonText"
      type="submit"
      :variant="!stateLocal ? 'transparent' : 'blocked'"
      class="w-100"
      :class="unhover ? 'block-btn-unblock' : ''"
      @click="toggleBlock"
      v-b-hover="hoverHandler"
    >
    </b-button>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import * as messangerActions from '@/store/modules/messanger/types/actions'

export default {
  name: 'UnblockChat',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    chat: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      unhover: false
    }
  },
  computed: {
    buttonText () {
      return this.stateLocal ? 'Blocked' : 'Unblock'
    },
    stateLocal: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    ...mapActions('messanger', {
      unblockChatById: messangerActions.UNBLOCK_CHAT_BY_ID
    }),
    async toggleBlock () {
      this.stateLocal = !this.stateLocal
      if (!this.stateLocal) {
        this.unhover = false
      }
      await this.unblockChatById(this.chat.id)
    },
    hoverHandler (isHovered) {
      if (isHovered) {
        this.unhover = true
      } else {
        if (this.stateLocal === true) {
          this.unhover = false
        }
      }
    },
    async unblockChat () {
      this.unblockChatById(this.chat.id)
      // // Using HTML string
      // const h = this.$createElement
      // const html = `<p>Are you sure you want to delete "${this.chat.info.title}"?</p> <p>This action cannot be undone.</p>`
      // const titleVNode = h('div', { domProps: { innerHTML: html } })
      // // const title = `Are you sure you want to delete "${this.chat.info.title}"? This action cannot be undone.`
      // const response = await this.$bvModal.msgBoxConfirm(
      //   [titleVNode],
      //   {
      //     title: '',
      //     okTitle: 'Block',
      //     okVariant: 'uppercase-warning',
      //     cancelTitle: 'Cancel',
      //     cancelVariant: 'uppercase'
      //   }
      // )
      // if (response) {
      // }
    }
  }
}
</script>

<style lang="scss">
.btn-blocked {
  border-color: red !important;
  background-color: transparent !important;
  color: red !important;
  font-weight: 500 !important;

  &:before {
    content: 'Blocked'
  }

  &.block-btn-unblock {
    border-color: #0096dd !important;
    background-color: #0096dd !important;
    color: #fff !important;

    &:hover {
      span {
        display: none;
      }

      &:before {
        content: 'Unblock';
      }
    }
  }
}

.nav-link {
  &.active {
    .btn-blocked {
      border-color: #fff !important;
      background-color: #0096dd !important;
      color: #fff !important;
    }
  }
}
</style>
