<template>
  <main>
    <div class="iq-card mb-0">
      <div class="iq-card-body p-2 scroller">
        <div
          v-for="(item, index) in members"
          :key="index"
          class="media cursor-pointer align-items-center mb-2"
        >
          <div :id="`popover-user-${item.id}`" class="iq-profile-avatar status-online">
            <img class="rounded-circle avatar-50" :src="item.avatar" :alt="item.fullName">
          </div>
          <div class="ml-3 d-flex flex-wrap align-items-center">
            <h6 class="mb-0 font-10-pt text-bold mr-2">{{ item.fullName }}</h6>
            <div v-if="administrator(item)" class="font-10-pt text-blue d-flex align-items-center"><i class="ri-star-fill mr-1"></i> <b-badge variant="main">Admin</b-badge></div>
            <div class="font-11 lh-11 text-left w-100">last seen {{ $d(new Date()) }}</div>
          </div>
          <b-popover custom-class="user-info-popover" :target="`popover-user-${item.id}`" placement="left"
            triggers="hover focus">
            <UserPopover :user="item" />
          </b-popover>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import UserPopover from '@/components/homeiz/user/UserPopoverPeep'

export default {
  name: 'ChatMembersList',
  components: {
    UserPopover
  },
  props: {
    members: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('messanger', {
      activeChatInfo: messangerGetters.GET_ACTIVE_CHAT_INFO
    })
  },
  methods: {
    administrator (user) {
      if (this.activeChatInfo.administrators && this.activeChatInfo.administrators.length) {
        return this.activeChatInfo.administrators.find(item => item.id === user.id)
      }
      return false
    }
  }
}
</script>
