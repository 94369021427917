<template>
  <div id="photo-grid" v-if="images.length">
    <post-photos-viewer :images="postImages" @delete-image="handleDelete" @replace-image="handleReplace" />
    <post-video-viewer :images="postVideos" @delete-image="handleDelete" @replace-image="handleReplace"/>
    <post-file-viewer :images="postFiles" @delete-image="handleDelete" @replace-image="handleReplace" />
  </div>
</template>

<script>
import PostPhotosViewer from './PhotosViewer.vue'
import PostVideoViewer from './VideoViewer.vue'
import PostFileViewer from './FileViewer.vue'

export default {
  components: { PostPhotosViewer, PostVideoViewer, PostFileViewer },
  name: 'MessangerPhotos',
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  computed: {
    postImages () {
      if (this.images.some(value => { return typeof value === 'object' })) {
        const result = this.images.filter((image) => image.type.split('/')[0] === 'image')
        return result.map(image => {
          return image.preview
        })
      } else {
        return this.images
      }
    },
    postVideos () {
      if (this.images.some(value => { return typeof value === 'object' })) {
        const result = this.images.filter((image) => image.type.split('/')[0] === 'video')
        return result.map(image => {
          return image
        })
      } else {
        return this.images
      }
    },
    postFiles () {
      if (this.images.some(value => { return typeof value === 'object' })) {
        const result = this.images.filter((image) => image.type.split('/')[0] === 'application')
        return result.map(image => {
          return image
        })
      } else {
        return this.images
      }
    }
  },
  methods: {
    handleDelete (index) {
      this.images.splice(index, 1)
    },
    handleReplace (index) {
      this.$emit('replace-image', index)
    }
  }
}
</script>
