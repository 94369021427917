<template>
  <div class="chat-searchbar w-85">
    <div class="form-group chat-search-data m-0">
      <input type="text" class="form-control round" id="chat-search" placeholder="Search" v-model="search">
      <i class="ri-search-line" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import * as messangerActions from '@/store/modules/messanger/types/actions'

export default {
  name: 'ChatSearch',
  data () {
    return { }
  },
  computed: {
    ...mapGetters('messanger', {
      searchString: messangerGetters.GET_SEARCH_STRING
    }),
    search: {
      get: function () {
        return this.searchString
      },
      set: function (newString) {
        this.updateSearchString(newString)
      }
    }
  },
  methods: {
    ...mapActions('messanger', {
      updateSearchString: messangerActions.UPDATE_SEARCH_STRING
    })
  }
}
</script>
