<template>
  <div class="chat chat-left me" :key="item.id">
    <div class="chat-user">
      <a class="avatar m-0">
        <img :src="item.creator.image" alt="avatar" class="avatar-35 rounded-circle" />
      </a>
    </div>
    <div class="chat-detail d-flex align-items-center" :class="isEmoji" @click.right="messageDropDown(item.id, $event)">
      <div v-if="item.media.length" class="mb-2">
          <chat-media :files="item.media"/>
          <div v-if="item.message" class="chat-message message-image w-100" :class="isEmoji">
            {{ item.message }}
          </div>
          <div class="message-time w-100 text-success">
            <span v-if="item.isEdited" class="mr-1">Edited</span>
            <span>{{ $d(item.createdAt, 'time', 'en-US') }}</span>
            <span class="font-20 ml-1">
              <i v-if="!item.isRead" class="ri-check-line"></i>
            </span>
            <!-- <span class="font-20 ml-1">
              <i class="ri-check-double-line"></i>
            </span> -->
          </div>
      </div>
      <div v-else :class="isSelected(item.id) ? 'selected' : ''" class="chat-message d-flex flex-wrap">
        <div class="addon-for-chat-window">
          <svg aria-hidden="true" height="12" viewBox="0 0 21 12" width="21" class="x10l6tqk xng853d xds687c"
            fill="var(--card-background)" style="transform: scale(-1, -1) translate(0px);">
            <path
              d="M20.685.12c-2.229.424-4.278 1.914-6.181 3.403L5.4 10.94c-2.026 2.291-5.434.62-5.4-2.648V.12h20.684z">
            </path>
          </svg>
        </div>
        <div>
          <div v-if="item.parent && !item.isForward" class="reply-message-text">
            <div class="text-bold">{{ item.parent.creator.fullName }}</div>
            <div>{{ item.parent.messagePreview }}</div>
          </div>
          <div v-if="item.parent && item.isForward">
            <div class="forward-message-text">Forwarded from {{ item.parent.creator.fullName }}</div>
            <div>{{ item.parent.messagePreview }}</div>
          </div>
          <div v-html="item.message" class="d-flex align-items-center html-message"></div>
          <!-- <div v-if="!item.isForward">{{ item.message }}</div> -->
        </div>
        <div class="message-time w-100 text-success mt-2">
          <span v-if="item.isEdited" class="mr-1">Edited</span>
          <span>{{ $d(item.createdAt, 'time', 'en-US') }}</span>
          <span class="font-20 ml-1">
            <i v-if="!item.isRead" class="ri-check-line"></i>
          </span>
          <!-- <span class="font-20 ml-1">
            <i class="ri-check-double-line"></i>
          </span> -->
        </div>
      </div>
      <div v-if="item.isRead">
        <img :src="item.recipient.avatar" alt="avatar" class="avatar-35 rounded-circle" :id="`notification-read-tooltip-${this._uid}`" />
        <b-tooltip :target="`notification-read-tooltip-${this._uid}`" custom-class="reserve-tooltip" placement="top" triggers="hover">
          Seen by {{ item.recipient.fullName }} at {{ $d(new Date(item.createdAt), 'short', 'en-US') }}, {{ $d(item.createdAt, 'time', 'en-US') }}
        </b-tooltip>
      </div>
    </div>
    <b-dropdown size="lg" variant="link" right ref="messageDropDown" toggle-class="text-decoration-none" no-caret>
      <b-dropdown-item-button><div class="d-flex align-items-center"><i class="ri-check-double-line"></i><div class="ml-2">1 Mike Maschinoff</div><img :src="item.creator.image" alt="avatar" class="ml-1 avatar-16 rounded-circle" /></div></b-dropdown-item-button>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item-button v-if="item.media.length"><save-as :media="item.media"/></b-dropdown-item-button>
      <b-dropdown-item-button><copy-selected-text :text="textSelection"/></b-dropdown-item-button>
      <b-dropdown-item-button><reply-message :id="item.id"/></b-dropdown-item-button>
      <b-dropdown-item-button><edit-message :id="item.id"/></b-dropdown-item-button>
      <b-dropdown-item-button><forward-message :id="item.id"/></b-dropdown-item-button>
      <b-dropdown-item-button><delete-message :id="item.id"/></b-dropdown-item-button>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item-button><select-message :title="selectTitle" :id="item.id"/></b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'

import DeleteMessage from './DeleteMessage.vue'
import EditMessage from './EditMessage.vue'
import ForwardMessage from './ForwardMessage.vue'
import ReplyMessage from './ReplyMessage.vue'
import CopySelectedText from './CopySelectedText.vue'
import SelectMessage from './SelectMessage.vue'
import ChatMedia from './ChatMedia.vue'
import SaveAs from './SaveAs.vue'

export default {
  components: { DeleteMessage, EditMessage, ReplyMessage, CopySelectedText, ForwardMessage, SelectMessage, ChatMedia, SaveAs },
  name: 'ChatMessageMy',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      textSelection: ''
    }
  },
  computed: {
    ...mapGetters('messanger', {
      selectedMessages: messangerGetters.GET_SELECTED_MESSAGES
    }),
    selectTitle () {
      return this.isSelected(this.item.id) ? 'Deselect' : 'Select'
    },
    isEmoji () {
      const str = this.item.message.replace(/<[^>]*>?/gm, '')
      return str ? '' : 'bg-transparent'
    }
  },
  methods: {
    messageDropDown (id, event) {
      event.preventDefault()
      const selectedText = window.getSelection()
      this.textSelection = selectedText.toString()
      this.$refs.messageDropDown.show()
    },
    isSelected (id) {
      return this.selectedMessages.includes(id)
    }
  }
}
</script>
