<template>
  <div class="d-flex align-items-center" @click="copySelectedText">
    <i class="ri-file-copy-line"></i>
    <div class="ml-2">Copy selected text</div>
  </div>
</template>

<script>

export default {
  name: 'CopySelectedText',
  props: {
    text: {
      type: String,
      required: false
    }
  },
  methods: {
    async copySelectedText () {
      try {
        await navigator.clipboard.writeText(this.text)
      } catch ($e) {

      }
    }
  }
}
</script>
